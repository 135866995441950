@import "utils";

:root {
  // each item in color map
  @each $name, $var in $vars {
    --#{$name}: #{$var};
  }
}

body {
  padding: 0;
  margin: 0;
  font: 400 16px 'Lato',Verdana,sans-serif;
}

a {
  text-decoration: none;
  @include var(color, secondary-text-color);
}

div, header, a, li, ul, section, main {
  box-sizing: border-box;
}

h1 {
  @include spacing(margin, l, 0);
  @include spacing(font-size, l);
  font-weight: 300;
}

h2 {
  @include spacing(margin, s, 0);
  @include breakpoint(s) {
    @include spacing(margin, m, 0);
  }
  @include spacing(font-size, m);
  font-weight: 300;
}