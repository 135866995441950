@import "src/utils";

.bride-page {
  @media (orientation: portrait) {
    background-image: url("../../../public/images/body-background-portrait.jpg");
    background-size: contain;
    background-color: #e6e7eb;
    background-position: center 4rem;
  }
  @media (orientation: landscape) {
    background-image: url("../../../public/images/body-background.jpg");
    background-size: cover;
    background-position: center 6rem;
  }

  background-repeat: no-repeat;
  background-attachment: fixed;

  min-height: calc(100vh - 15rem);
  display: flex;
  flex-direction: column;

  &-content-box {
    margin: 50vh 0 0;
    &:last-child {
      margin: 50vh 0;
    }
    &:not(:first-child),
    &:not(:last-child) {
        @include spacing(padding, xs);
        .bride-page-content {
          @include breakpoint(s) {
            @include spacing(padding, xxs, m, xs);
          }
          border-radius: 4px;
        }
    }

    @include var(background-color, secondary-color);
  }
  &-content {

    @include spacing(max-width, 1200);
    @include var(color, primary-text-color);
    width: 100%;
    margin: auto;
    @include spacing(padding, xs);
    @include breakpoint(s) {
      @include spacing(padding, l);
    }

    &-images {
      display: flex;
      flex-wrap: wrap;
      @include elevation;

      &-link {
        @include spacing(border-radius, 4);
        @include spacing(padding, xxs);
        @include var(background-color, secondary-color);
        @include var(color, primary-color);
        display: block;
        @include spacing(margin, xxs, 0, 0);
        text-align: center;
      }
      &-img {
        flex: 1;

        img {
          width: 100%;
          display: block;
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }
}