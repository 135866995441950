@import "src/utils";

.index-page {
  @media (orientation: portrait) {
    background-image: url("../../../public/images/body-background-portrait.jpg");
    background-size: cover;
  }
  @media (orientation: landscape) {
    background-image: url("../../../public/images/body-background.jpg");
    background-size: cover;
  }

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 4.5rem;
  min-height: calc(100vh - 15rem);
  display: flex;
  flex-direction: column;

  &-content-box {
    margin: 50vh 0 0;

    @include var(background-color, secondary-color);

    &:last-child {
      margin: 50vh 0;
    }
  }
  &-content {
    @include spacing(max-width, 1200);
    @include var(color, primary-text-color);
    width: 100%;
    margin: auto;
    @include spacing(padding, xs);
    @include breakpoint(s) {
      @include spacing(padding, l);
    }

    &-bride,
    &-groom {
      display: flex;
      flex-direction: column;
      @include breakpoint(s) {
        flex-direction: row;
      }
      .image-slider {
        order: 1;
        width: 100%;
        @include elevation;
        @include spacing(margin-top, l);
        @include breakpoint(s) {
          order: 0;
          @include spacing(width, 400);
          @include spacing(margin-right, l);
          @include spacing(margin-top, 0);
        }

        &-wrapper {
          &.autoscroll {
            transform: translateX(0);
            animation: autoscroll-index 30s ease-in-out infinite;
          }

        }
      }

      &-text {
        flex: 1;
        order: 0;
        display: flex;
        flex-direction: column;

        p {
          flex: 1;
        }
      }

      .index-page-content-link {
        align-self: flex-end;
        @include var(background-color, primary-color);
        @include var(color, secondary-color);
        @include spacing(border-radius, 4);
        @include spacing(padding, xxs);
      }
    }
  }
}

@keyframes autoscroll-index {
  0% { transform: translateX(0); }
  50% { transform: translateX(-250%); }
  100% { transform: translateX(0); }
}