@import "src/utils";

.header {
  @include var(background-color, header-color);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;

  &-mobile {
    @include spacing(padding, xs);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &-menu {
      cursor: pointer;
      path {
        @include var(stroke, primary-color);
      }
    }
    &-meeting {
      @include spacing(margin-right, xs);
    }
    &-home {
      position: absolute;
      left: 0;
      @include spacing(margin, 0, xs);
      @include spacing(width, xxl);

      img {
        @include spacing(width, 102);

      }
    }
    @include breakpoint(s) {
      display: none;
    }
  }
  &-content {
    position: absolute;
    background-color: #ffffff;
    top: 0;
    @include elevation;
    flex-direction: column;
    width: 240px;
    height: 100vh;
    transform: translateX(-100%);
    @include breakpoint(s) {
      display: flex;
      flex-direction: row;
      height: inherit;
      width: 100%;
      transform: inherit;
      position: inherit;
    }

    &.open {
      transform: translateX(0);
      @include breakpoint(s) {
        transform: inherit;
      }
    }

    &-backdrop.open {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      @include var(background-color, backdrop-color);
      @include breakpoint(s) {
        display: none;
      }
    }

    &-home {
      display: block;
      @include spacing(margin, xs);
      @include breakpoint(s) {
        height: 6rem;
      }
      @include breakpoint(m) {
        @include spacing(margin, xs);
      }
      @include breakpoint(l) {
        position: absolute;
        height: auto;
        @include spacing(margin, xs, xl);
      }
    }
  }

  &-navigation {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    @include breakpoint(m) {
      align-items: center;
    }
    &-items {
      @include spacing(padding, 0);

      @include spacing(max-width, 820);
      @include breakpoint(s) {
        @include spacing(padding, 0, s);
      }
      width: 100%;
      display: flex;
      flex-direction: column;
      list-style: none;
      @include breakpoint(s) {
        flex-direction: row;
      }
    }

    &-item {
      flex: 1;
      display: flex;
      &-link {
        @include spacing(padding, xs);
        @include var(color, primary-color);
        @include spacing(font-size, xs);
        font-weight: 300;
        display: flex;
        align-items: center;
        width: 100%;
        svg {
          @include spacing(margin-right, xxs);
          path {
            @include var(fill, primary-color);
          }
        }

        &.active {
          @include var(border-bottom-color, primary-color);
          @include var(color, primary-color);
          border-bottom-style: solid;
          border-bottom-width: 1px;
        }
      }
    }

  }
}