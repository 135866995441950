.image-slider {
  overflow: hidden;

  &-wrapper {
    display: flex;
  }
  &-image {
    object-fit: cover;
  }
}
