@import "src/utils";

.footer {
  &-content {
    @include var(color, primary-color);
    display: flex;
    flex-direction: column;
    @include spacing(max-width, 1200);
    margin: auto;
    @include spacing(padding, s, 0);

    @include breakpoint(s) {
      flex-direction: row;
      @include spacing(padding, s);
    }
    &-address {
      flex: 1;
      @include spacing(padding, xs);
      display: flex;
      flex-direction: column;
    }

    &-open {
      flex: 1;
      @include spacing(padding, xs);
      &-time {
        @include spacing(margin, 4, 0);
        list-style: none;
        padding: 0;

        li {
          @include spacing(padding, 4, 0);

        }
      }
    }

    &-links {
      @include spacing(padding, xs);
      flex: 1;
      display: flex;
      flex-direction: column;

      a {
        @include spacing(padding, 4, 0);
        @include var(color, primary-color);

        &:first-child {
          @include spacing(padding, 0, 0, 4);
        }
      }
    }
  }
}