@import "src/utils";

.gallery-page {
  @media (orientation: portrait) {

    background-size: contain;
    background-color: #e6e7eb;
    background-position: center 4rem;

    &.brautkleider,
    &.accessoires,
    &.schuhe {
      background-image: url("../../../public/images/body-background-portrait.jpg");
    }
    &.anzuege {
      background-image: url("../../../public/images/groom-background-portrait.jpg");
    }

  }
  @media (orientation: landscape) {
    background-size: cover;
    background-position: center 6rem;
    &.brautkleider,
    &.accessoires,
    &.schuhe {
      background-image: url("../../../public/images/body-background.jpg");
    }
    &.anzuege {
      background-image: url("../../../public/images/groom-background.jpg");
    }
  }

  background-repeat: no-repeat;
  background-attachment: fixed;

  min-height: calc(100vh - 15rem);
  display: flex;
  flex-direction: column;
  @include spacing(padding, 96, 0);

  &-content-box {
    margin: 50vh 0 0;
    @include var(background-color, secondary-color);
  }
  &-content {
    @include spacing(max-width, 1200);
    @include var(color, primary-text-color);
    @include spacing(padding, xs);
    @include breakpoint(s) {
      @include spacing(padding, l);
    }
    width: 100%;
    margin: auto;

    h1 {
      text-transform: capitalize;
    }

    &-images-links {
      text-transform: capitalize;
      display: flex;
      flex-wrap: wrap;
      @include spacing(margin-bottom, m);
    }

    &-images-link {
      @include spacing(border-radius, 4);
      @include spacing(padding, xxs);
      @include var(background-color, secondary-color);
      @include var(color, primary-text-color);
      @include spacing(margin, xxs);
      @include elevation;
      display: flex;
    }
  }

  .react-photo-gallery--gallery {
    @include spacing(margin-bottom, m);
    @include spacing(padding, 4);
    @include spacing(border-radius, 4);
    @include var(background-color, secondary-color);
    @include elevation;
  }

  .bride-icon,
  .groom-icon {
    @include spacing(margin-right, xxs);
    path {
      @include var(fill, primary-text-color);
    }
  }
}