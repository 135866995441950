@import "src/utils";

.imprint-page {
  @media (orientation: portrait) {
    background-image: url("../../../public/images/body-background-portrait.jpg");
    background-size: cover;
  }
  @media (orientation: landscape) {
    background-image: url("../../../public/images/body-background.jpg");
    background-size: cover;
  }

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 4.5rem;
  min-height: calc(100vh - 15rem);
  display: flex;
  flex-direction: column;

  &-content-box {
    margin: 50vh 0 0;

    @include var(background-color, secondary-color);

    &:last-child {
      margin: 50vh 0;
    }
  }
  &-content {
    @include spacing(max-width, 1200);
    @include var(color, primary-text-color);
    width: 100%;
    margin: auto;
    overflow: auto;
    @include spacing(padding, xs);
    @include breakpoint(s) {
      @include spacing(padding, l);
    }

    p {
      display: flex;
      flex-direction: column;
    }
  }
}
