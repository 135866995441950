@use "sass:math";

$vars: (
  primary-color: #5f021f,
  primary-text-color: #9a6a3a,
  secondary-color: rgba(255,255,255,0.87),
  secondary-text-color: rgba(0, 0, 0, 0.87),
  backdrop-color: rgba(0, 0, 0, 0.2),
  header-color: rgba(255, 255, 255, 1),
  elevation-color-low-opacity: rgba(33, 37, 41, 0.1),
  elevation-color-high-opacity: rgba(33, 37, 41, 0.05),
);

$spacer: 1rem;

$spacings: (
  xxs: 0.5,
  xs: 1,
  s: 1.5,
  m: 2,
  l: 2.5,
  xl: 3,
  xxl: 3.5,
);

$media-direction: min;

$breakpoints: (
  default: "",
  xs: 480px,
  s: 667px,
  m: 1024px,
  l: 1280px,
  xl: 1680px,
);

@function pxToRem($pxValue) {
  @return math.div($pxValue, 16) * 1rem;
}

@function calcSpacing($spacers) {
  $result: null;
  @each $var in $spacers {
    @if map-has-key($spacings, $var) {
      $result: $result #{map-get($spacings, $var) * $spacer};
    } @else {
      $result: $result #{pxToRem($var)};
    }
  }

  @return $result;
}

@mixin var($property, $varName) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName});
}

@mixin boxShadow($top, $left, $right, $blur, $color: null) {
  @if ($color) {
    box-shadow: $top $left $right $blur map-get($vars, $color);
    box-shadow: $top $left $right $blur var(--$color);
  } @else {
    box-shadow: $top $left $right $blur map-get($vars, shadow-color);
    box-shadow: $top $left $right $blur var(--shadow-color);
  }
}

@mixin elevation() {
  $elevationHighOpacity: map-get($vars, elevation-color-high-opacity);
  $elevationLowOpacity: map-get($vars, elevation-color-low-opacity);
  box-shadow: 0 3px 6px 0 $elevationLowOpacity, 0 0 0 1px $elevationLowOpacity,
    0 -1px 0 0 $elevationHighOpacity;
  box-shadow: 0 3px 6px 0 var(--elevation-color-low-opacity),
    0 0 0 1px var(--elevation-color-low-opacity),
    0 -1px 0 0 var(--elevation-color-high-opacity);
}

@mixin elevation-top() {
  $elevationHighOpacity: map-get($vars, elevation-color-high-opacity);
  $elevationLowOpacity: map-get($vars, elevation-color-low-opacity);
  box-shadow: 0 -3px 6px 0 $elevationLowOpacity, 0 0 0 -1px $elevationLowOpacity,
    0 1px 0 0 $elevationHighOpacity;
  box-shadow: 0 -3px 6px 0 var(--elevation-color-low-opacity),
    0 0 0 -1px var(--elevation-color-low-opacity),
    0 1px 0 0 var(--elevation-color-high-opacity);
}

@mixin spacing($property, $spacers...) {
  #{$property}: calcSpacing($spacers);
}

@mixin breakpoint($breakpoint, $direction: $media-direction) {
  // Es wird gecheckt ob der Wert in der Sass Map vorhanden ist
  @if map-has-key($breakpoints, $breakpoint) {
    // Ausgabe
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  // Wenn ihr oben einen Wert eingetragen habt wird hier gecheckt ob ihr einen Einheit angehängt habt. Falls nicht wird px genutzt.
  @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;

    // Ausgabe
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}
