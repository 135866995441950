@import "src/utils";

.App {
}

.app-info-important {
  position: fixed;
  display: flex;
  margin-top: 72px;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  text-align: right;

  @include spacing(padding, xxs, xxs, xxs, 140);
  @include breakpoint(s) {
    @include spacing(padding, xxs);
    margin-top: 128px;
  }
  @include var(background-color, secondary-color);
  @include var(color, primary-color);
}